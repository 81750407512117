import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
/**新增站点
localhost:8056/saas/wanwu-manage/web/site/addSite
请求方式：POST
请求参数：
 */
export function addSite(params) {
  return request({
    url: envInfo.bgApp.sitePath + '/addSite',
    method: 'post',
    data: params,
  });
}
/**获取站点（分页）
localhost:8056/saas/wanwu-manage/web/site/page 
请求方式：GET
请求参数：
 */
export function getSitePage(params) {
  return request({
    url: envInfo.bgApp.sitePath + '/page',
    method: 'get',
    params,
  });
}
/**获取站点详情
localhost:8056/saas/wanwu-manage/web/site/findSiteDetailById
请求方式：GET
请求参数：siteId
 */
export function getSiteDetail(params) {
  return request({
    url: envInfo.bgApp.sitePath + '/findSiteDetailById',
    method: 'get',
    params,
  });
}
/**修改点位
localhost:8056/saas/wanwu-manage/web/site/updateSite
请求方式：POST
请求参数：
 */
export function updateSite(params) {
  return request({
    url: envInfo.bgApp.sitePath + '/updateSite',
    method: 'post',
    data: params,
  });
}
/**删除点位
localhost:8056/saas/wanwu-manage/web/site/deleteSite
请求方式：POST
请求参数：siteId
 */
export function deleteSite(params) {
  return request({
    url: envInfo.bgApp.sitePath + '/deleteSite',
    method: 'post',
    data: params,
  });
}
/**获取设备
 http://localhost:8107/saas/things/device/list
请求方式：GET
请求参数：description
 */
// export function getDevice(params) {
//   return request({
//     url: envInfo.bgApp.thingsPath + '/device/list',
//     method: 'get',
//     params,
//   });
// }
export function getDevice(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/pageForWanwu',
    method: 'get',
    params,
  });
}
/**获取设备点位
 http://localhost:8107/saas/things/product/tsl
请求方式：GET
请求参数：productKey
 */
export function getDevicePoint(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/tsl',
    method: 'get',
    params,
  });
}

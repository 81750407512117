<template>
  <div class="app-container sit-manage-comp">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createPlace">新增站点</el-button>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>

    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>

    <!-- 站点弹出框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="site-dialog"
      width="50%"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ title }}
      </template>
      <div class="scroll-view">
        <el-form
          v-loading="createLoading"
          label-position="left"
          :model="siteForms"
          ref="siteForm"
          :rules="siteFormRules"
        >
          <el-row>
            <el-col :span="11">
              <el-form-item
                label="地点名称"
                :label-width="formLabelWidth"
                prop="placeName"
                required
              >
                <el-select
                  v-model="siteForms.placeName"
                  filterable
                  remote
                  reserve-keyword
                  :remote-method="siteRemoteMethod"
                  clearable
                  placeholder="请选择"
                  style="width: 100%"
                >
                  <el-option
                    @click.native="selectPlaceName(item)"
                    v-for="item in locationOptions"
                    :key="item.placeId"
                    :label="item.placeName"
                    :value="item.placeName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="站点名称"
                prop="siteName"
                :label-width="formLabelWidth"
                style="margin-left: 20px"
              >
                <el-input
                  :disabled="isEdit"
                  v-model="siteForms.siteName"
                  maxlength="6"
                  :show-word-limit="true"
                  clearable
                  :placeholder="$t('site.siteName')"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="23">
              <el-form-item
                label="详细地址"
                prop="detailAddress"
                :label-width="formLabelWidth"
              >
                <el-input
                  :disabled="isEdit"
                  v-model="siteForms.detailAddress"
                  maxlength="100"
                  :show-word-limit="true"
                  clearable
                  :placeholder="$t('site.address')"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="11">
              <el-form-item
                label="经度"
                prop="longitude"
                :label-width="formLabelWidth"
              >
                <el-input
                  :disabled="isEdit"
                  v-model.number="siteForms.longitude"
                  :show-word-limit="true"
                  clearable
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="纬度"
                prop="latitude"
                :label-width="formLabelWidth"
                style="margin-left: 20px"
              >
                <el-input
                  :disabled="isEdit"
                  v-model.number="siteForms.latitude"
                  :show-word-limit="true"
                  clearable
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <i
                class="el-icon-location-information"
                style="font-size: 30px; color: #409eff; margin-left: 10px"
                @click="openMap"
              ></i>
            </el-col>
          </el-row>
          <!-- 选择设备 -->
          <div>
            <div
              v-for="(item, deviceIndex) in siteForms.siteBindDevices"
              :key="deviceIndex"
            >
              <el-row>
                <el-col :span="23">
                  <el-form-item
                    label="选择设备"
                    prop="siteForms"
                    :label-width="formLabelWidthEquip"
                    style="padding-left: 10px"
                  >
                    <el-select
                      style="width: 100%"
                      v-model="item.productKey"
                      filterable
                      remote
                      reserve-keyword
                      :remote-method="deviceRemoteMethod"
                      clearable
                      placeholder="请选择"
                      :loading="deviceLoading"
                      @change="
                        (val) => {
                          openEquip(val, deviceIndex);
                        }
                      "
                    >
                      <el-option
                        v-for="p in equipmentOptions"
                        :key="p.productKey + p.deviceName"
                        :label="p.deviceName"
                        :value="
                          p.productKey +
                          '/' +
                          p.deviceName +
                          '/' +
                          p.description
                        "
                      >
                        <span style="float: left">{{ p.deviceName }}</span>
                        <span
                          style="float: right; color: #8492a6; font-size: 13px"
                          >{{ p.description }}</span
                        >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="1">
                  <i
                    class="el-icon-delete"
                    style="
                      position: relative;
                      top: 4px;
                      font-size: 26px;
                      margin-left: 12px;
                      color: rgb(228, 116, 112);
                    "
                    @click="devicesClose(deviceIndex)"
                  ></i>
                </el-col>
              </el-row>
              <el-row>
                <section
                  v-for="(it, idx) in item.siteDeviceBindPoints"
                  :key="idx"
                >
                  <el-form
                    :rules="pointFormRules"
                    :model="it"
                    :ref="'pointerRuleForm_' + deviceIndex + '_' + idx"
                  >
                    <el-row style="margin-left: 80px">
                      <el-col :span="10">
                        <el-form-item
                          label="点位名称"
                          prop="pointName"
                          :label-width="formLabelWidth"
                          :rules="[
                            {
                              required: true,
                              validator: (rule, value, callback) =>
                                validatorPointers(
                                  rule,
                                  value,
                                  callback,
                                  deviceIndex,
                                  idx
                                ),
                              trigger: ['blur', 'change'],
                            },
                          ]"
                        >
                          <el-input
                            :disabled="isEdit"
                            v-model="it.pointName"
                            :show-word-limit="true"
                            maxlength="6"
                            autocomplete="off"
                            class="pointInput"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="7">
                        <el-form-item
                          label="计量单位"
                          prop="unit"
                          :label-width="formLabelWidth"
                          style="margin-left: 10px"
                        >
                          <el-input
                            :disabled="isEdit"
                            v-model="it.unit"
                            :show-word-limit="true"
                            maxlength="5"
                            autocomplete="off"
                            required
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <!-- 待定 -->
                      <el-col :span="4">
                        <el-form-item
                          label="设备图标"
                          prop="icon"
                          :label-width="formLabelWidth"
                          style="margin-left: 20px"
                        >
                          <div class="form-image-line">
                            <el-upload
                              class="avatar-uploader"
                              :action="uploadPath"
                              :with-credentials="true"
                              :show-file-list="false"
                              :on-success="
                                (response, file, fileList) =>
                                  handleAvatarSuccess(
                                    response,
                                    file,
                                    fileList,
                                    deviceIndex,
                                    idx
                                  )
                              "
                              :before-upload="beforeAvatarUpload"
                            >
                              <img
                                v-if="it.imageUrl"
                                :src="it.imageUrl"
                                class="avatar"
                              />
                              <i
                                v-else
                                class="el-icon-plus avatar-uploader-icon"
                              ></i>
                            </el-upload>
                            <i
                              class="el-icon-delete"
                              @click="deleteEnterpriseLogo(deviceIndex, idx)"
                              v-show="it.imageUrl"
                            ></i>
                          </div>
                        </el-form-item>
                      </el-col>
                      <el-col :span="3">
                        <i
                          class="el-icon-error"
                          style="font-size: 28px; padding-left: 75px"
                          @click="parameterClose(deviceIndex, idx)"
                        ></i>
                      </el-col>
                    </el-row>
                    <el-row style="margin-left: 80px">
                      <el-col :span="18">
                        <el-form-item
                          style="margin-left: 10px"
                          label="正常范围"
                          prop="range"
                          :label-width="formLabelWidthRange"
                          :rules="[
                            {
                              required: false,
                              validator: (rule, value, callback) =>
                                validatorRange(
                                  rule,
                                  value,
                                  callback,
                                  deviceIndex,
                                  idx
                                ),
                              trigger: ['blur', 'change'],
                            },
                          ]"
                        >
                          <el-input
                            :disabled="isEdit"
                            v-model="it.minValue"
                            :show-word-limit="true"
                            autocomplete="off"
                            style="width: 104px"
                          ></el-input>
                          ~
                          <el-input
                            :disabled="isEdit"
                            v-model="it.maxValue"
                            :show-word-limit="true"
                            autocomplete="off"
                            style="width: 104px"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                  <el-row
                    class="added_button"
                    v-if="idx === item.siteDeviceBindPoints.length - 1"
                  >
                    <el-col :span="23">
                      <el-button
                        type="primary"
                        @click="addDevicePointDetail(item, deviceIndex)"
                        >增加点位</el-button
                      >
                    </el-col>
                  </el-row>
                </section>
              </el-row>
            </div>
          </div>
          <el-row>
            <el-col :span="23" style="padding-left: 80px">
              <div class="selectEquip" @click="equipAdd()">选择设备</div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer"> -->
      <div class="site-buttons-container">
        <el-button @click="handleClose">{{ $t("commons.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="handleLocationSave"
          :loading="is_click"
          >{{ $t("commons.save") }}</el-button
        >
      </div>
      <!-- <span class="black"></span> -->
      <!-- </span> -->
    </el-dialog>

    <!-- 地图弹出框 -->
    <el-dialog
      :visible.sync="mapDialogVisible"
      :show-close="true"
      width="50%"
      height="650px"
    >
      <div class="map-search-line">
        <el-input
          v-model="mapKeyword"
          style="width: 100%; margin-right: 20px"
          placeholder="请输入地点"
        />
        <!-- <el-button type="primary">搜索</el-button> -->
      </div>
      <div class="map-container-box">
        <baidu-map
          v-if="mapDialogVisible"
          class="map-container"
          :center="{ lng: mapSiteObj.longitude, lat: mapSiteObj.latitude }"
          :zoom="16"
          @moving="syncCenterAndZoom"
          @moveend="syncCenterAndZoom"
          @zoomend="syncCenterAndZoom"
        >
          <bm-local-search
            :keyword="mapKeyword"
            :auto-viewport="false"
            @infohtmlset="infoSelectHanlder"
            @markersset="showSuggestion"
          ></bm-local-search>
          <bm-marker
            :position="{ lng: mapSiteObj.longitude, lat: mapSiteObj.latitude }"
            :dragging="true"
            @dragend="markerDragOver"
          >
            <!-- <bm-info-window
              :show="show"
              @close="infoWindowClose"
              @open="infoWindowOpen"
              style="font-size: 13px"
            ></bm-info-window> -->
          </bm-marker>
        </baidu-map>
      </div>

      <div class="map-buttons-container">
        <el-button
          v-show="showFooterButtons"
          type="primary"
          @click="mapSaveAndClose"
          >保存并返回</el-button
        >
        <el-button v-show="showFooterButtons" @click="mapCancelAndClose"
          >取消</el-button
        >
      </div>
    </el-dialog>

    <!-- 添加XXX设备下点位的弹出框 -->
    <el-dialog
      :visible.sync="equipDialogVisible"
      width="50%"
      height="auto"
      :before-close="handleCloseEquip"
    >
      <template slot="title">
        添加 {{ equipDialogDeviceName }}设备下点位
      </template>
      <div class="search-box">
        <el-input
          v-model="search"
          @keyup.enter.native="tableDataEquipM"
          placeholder="请输入内容"
        ></el-input>
        <div class="buttonGroup">
          <el-button type="primary" @click="tableDataEquipM()">
            {{ $t("commons.search") }}</el-button
          >
          <el-button @click="resetPoint()">{{ $t("commons.reset") }}</el-button>
        </div>
      </div>

      <!-- 表单 -->
      <el-table
        class="equipEable"
        v-loading="loading"
        ref="multipleTable"
        :data="tableDataEquip"
        border
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          :selectable="selectAbleHandler"
          label="全选"
          width="55"
        >
        </el-table-column>
        <el-table-column label="属性名称" prop="propertyName">
        </el-table-column>
        <el-table-column label="描述" prop="description"> </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseEquip">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleEquipSave">{{
          $t("commons.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import finalTable from "@/components/FinalTable";
import { getPlaceDetail } from "@/api/ruge/vlink/wanwu/locationManagement";
import {
  addSite,
  getSitePage,
  getSiteDetail,
  updateSite,
  deleteSite,
  getDevice,
  getDevicePoint,
} from "@/api/ruge/vlink/wanwu/siteManagement";
import { getAllPlace } from "@/api/ruge/vlink/wanwu/historicalRecord";
export default {
  name: "siteManagement",
  components: {
    finalTable,
  },
  data() {
    const validatorPointers = (
      rule,
      value,
      callback,
      deviceIndex,
      pointerIndex
    ) => {
      const { pointName } =
        this.siteForms.siteBindDevices[deviceIndex].siteDeviceBindPoints[
          pointerIndex
        ];
      if (!pointName) {
        callback(new Error("请填写点位名称"));
      } else if (pointName.length > 6) {
        callback(new Error("点位名称长度不能超过6个字符"));
      } else {
        callback();
      }
    };
    const validatorRange = (
      rule,
      value,
      callback,
      deviceIndex,
      pointerIndex
    ) => {
      const { minValue, maxValue } =
        this.siteForms.siteBindDevices[deviceIndex].siteDeviceBindPoints[
          pointerIndex
        ];
      // if (!String(minValue) || !String(maxValue)) {
      //   callback(new Error("正常范围为必填字段"));
      // } else if (minValue > maxValue) {
      //   callback(new Error("最小值不能大于最大值"));
      // } else {
      //   callback();
      // }
      if (Number(minValue) > Number(maxValue)) {
        callback(new Error("最小值不能大于最大值"));
      } else {
        callback();
      }
    };
    const validatorIcons = (
      rule,
      value,
      callback,
      deviceIndex,
      pointerIndex
    ) => {
      if (
        !this.siteForms.siteBindDevices[deviceIndex].siteDeviceBindPoints[
          pointerIndex
        ].icon
      ) {
        callback(new Error("请上传设备图标"));
      } else {
        callback();
      }
    };
    return {
      is_click: false,
      validatorIcons,
      validatorRange,
      validatorPointers,
      showFooterButtons: true,
      deviceLoading: false,
      mapKeyword: "",
      // 上传地址
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      dialogVisible: false, //站点管理显示
      createLoading: false, //站点管理弹出框的加载
      loadingFlag: false,
      loading: false, //添加设备的弹出框加载
      formLabelWidth: "80px",
      formLabelWidthEquip: "70px",
      formLabelWidthRange: "70px",
      mapDialogVisible: false, //地图弹出框可见
      show: false,
      fileList: [], //设备图标
      equipmentOptions: [], //选择设备数据源
      locationOptions: [], //地点名称数据源
      equipmentValue: [], //选择设备绑定值
      locationValue: [], //地点名称绑定值
      equipDialogVisible: false, //设备弹出框可见
      equipDialogDeviceName: "",
      equipBoxOpen: true, //选择设备打开
      parameterBoxOpen: false, //设备弹出框打开
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          {
            prop: "siteName",
            label: this.$t("site.formGroup.siteName"),
            width: "",
          },
          {
            prop: "placeName",
            label: this.$t("所属地点"),
            width: "",
          },

          {
            prop: "detailAddress",
            label: this.$t("site.formGroup.address"),
            width: "",
          },
          {
            prop: "pointNum",
            label: this.$t("site.formGroup.pointNumber"),
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("site.formGroup.operation"),
            width: "100",
          },
        ],
        tableData: [],
        multipleSelection: [],
        // 搜索行配置
        searchLineConfig: {
          placeName: {
            type: "input",
            label: "地点名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入地点名称",
            prefixIcon: "el-icon-search",
          },
          siteName: {
            type: "input",
            label: "站点名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入站点名称",
            prefixIcon: "el-icon-search",
          },
          detailAddress: {
            type: "input",
            label: "地址",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入地址",
            prefixIcon: "el-icon-search",
          },
          // pointNum: {
          //   type: "input",
          //   label: "点位数量",
          //   value: "",
          //   actionType: "goSearch",
          //   placeholder: "请输入点位数量",
          //   prefixIcon: "el-icon-search",
          // },
          // operation: {
          //   type: "button",
          //   filterCount: "",
          //   actionType: "clickEvent",
          //   eventName: "showMoreFilters",
          //   label: "更多筛选",
          // },
        },
        // 表格内容配置
        detailConfig: {
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          placeName: {
            inline: true,
            value: "",
          },
          siteName: {
            inline: true,
            value: "",
          },
          detailAddress: {
            inline: true,
            value: "",
          },
          pointNum: {
            inline: true,
            value: "",
          },
        },
        //请求数据
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      //站点管理弹出框表单数据
      siteForms: {
        placeId: "",
        placeName: "",
        siteName: "",
        detailAddress: "",
        longitude: "",
        latitude: "",
        description: "",
        //设备数组
        siteBindDevices: [],
        currentDevices: 0, //
        siteId: "",
      },
      //弹出框数据
      title: "",
      isEdit: false,
      //弹出框绑定的表单规则
      siteFormRules: {
        placeName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("site.validate.placeNameRequired"),
          },
          {
            // eslint-disable-next-line
            pattern: /^[\u4E00-\u9FA5A-Za-z0-9，。,（）、().\-\/]+$/,
            message:
              "地点名称仅支持汉字、大小写英文，阿拉伯数字以及常规标点符号。",
            trigger: ["blur", "change"],
          },
        ],
        siteName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("site.validate.siteNameRequired"),
          },
          {
            // eslint-disable-next-line
            pattern: /^[\u4E00-\u9FA5A-Za-z0-9，。,（）、().\-\/]+$/,
            message:
              "站点名称仅支持汉字、大小写英文，阿拉伯数字以及常规标点符号。",
            trigger: ["blur", "change"],
          },
        ],
        detailAddress: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("site.validate.addressRequired"),
          },
          {
            // eslint-disable-next-line
            pattern: /^[\u4E00-\u9FA5A-Za-z0-9，。,（）、().\-\/]+$/,
            message:
              "地址名称仅支持汉字、大小写英文，阿拉伯数字以及常规标点符号。",
            trigger: ["blur", "change"],
          },
        ],
        longitude: [
          {
            required: true,
            trigger: ["blur"],
            message: this.$t("site.validate.longitudeRequired"),
          },
        ],
        latitude: [
          {
            required: true,
            trigger: ["blur"],
            message: this.$t("site.validate.latitudeRequired"),
          },
        ],
      },
      //设备参数规则
      pointFormRules: {
        pointName: [
          {
            required: true,
            trigger: ["blur"],
            message: "点位名称为必填字段",
          },
          {
            // eslint-disable-next-line
            pattern: /^[\u4E00-\u9FA5A-Za-z0-9，。,（）、().\-\/]+$/,
            message: "点位名称仅支持汉字、大小写英文，数字或标点符号。",
            trigger: ["blur", "change"],
          },
        ],
        // unit: [
        //   {
        //     required: tfarue,
        //     trigger: ["blur"],
        //     message: "计量单位为必填字段",
        //   },
        //   // {
        //   //   pattern: /[^\u4e00-\u9fa5\w,\.]/,
        //   //   message: "计量单位仅支持汉字、大小写英文，数字或标点符号。",
        //   //   trigger: ["blur", "change"],
        //   // },
        // ],
      },
      search: "", //点位搜索词
      tableDataEquip: [], //点位表单绑定的数据
      tableDataEquip1: [],
      //获取页面数据的请求参数
      listQuery: {
        placeName: "",
        siteName: "",
        detailAddress: "",
        current: 1,
        rowCount: 10,
      },
      selectList: [], //点位选中值数组
      deviceResourceList: [],
      siteResourceList: [], //地点列表数据临时数据
      selectedPointers: [], //记录当前设备已选点位
      mapSiteObj: {
        longitude: "",
        latitude: "",
      },
    };
  },
  mounted() {
    this.findSitePage();
  },
  watch: {
    loadingFlag(val) {
      this.$refs.finalTableRef.loadingToggle(val);
    },
  },
  methods: {
    showSuggestion() {
      this.showFooterButtons = false;
    },
    infoSelectHanlder() {
      this.showFooterButtons = true;
      this.mapKeyword = "";
    },
    //点位全选
    selectAbleHandler(row) {
      return (
        this.selectedPointers.filter((item) => {
          return (
            item.moduleName === row.moduleName &&
            item.propertyName === row.propertyName
          );
        }).length === 0
      );
    },
    //删除设备图标
    deleteEnterpriseLogo(deviceIndex, pointerIndex) {
      this.siteForms.siteBindDevices[deviceIndex].siteDeviceBindPoints[
        pointerIndex
      ].icon = "";
      this.siteForms.siteBindDevices[deviceIndex].siteDeviceBindPoints[
        pointerIndex
      ].imageUrl = "";
    },
    //删除设备图标
    handleAvatarSuccess(files, file, fileList, deviceIndex, pointerIndex) {
      let tempObj = JSON.parse(JSON.stringify(this.siteForms));
      tempObj.siteBindDevices[deviceIndex].siteDeviceBindPoints[
        pointerIndex
      ].icon = files[0].fileId;
      tempObj.siteBindDevices[deviceIndex].siteDeviceBindPoints[
        pointerIndex
      ].imageUrl =
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        files[0].fileId;
      this.siteForms = tempObj;
    },
    //上传
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 3MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      return returnFlag;
    },
    //添加设备弹出框的搜索按钮
    tableDataEquipM() {
      const search = this.search;
      this.tableDataEquip = this.tableDataEquip1.filter((item) => {
        return item.propertyName
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      });
      // if (search) {
      //   this.tableDataEquip = this.tableDataEquip1.filter((dataNews) => {
      //     return Object.keys(dataNews).some((key) => {
      //       return String(dataNews[key]).toLowerCase().indexOf(search) > -1;
      //     });
      //   });
      // } else {
      //   this.tableDataEquip = this.tableDataEquip1;
      // }
    },
    //地点数据源远程搜索
    siteRemoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.locationOptions = this.siteResourceList.filter((item) => {
            return (
              item.placeName.toLowerCase().indexOf(query.toLowerCase()) > -1
            );
          });
        }, 200);
      } else {
        this.locationOptions = this.siteResourceList;
      }
    },
    //获取地点列表数据
    getAllPlaceDetail() {
      getAllPlace().then((res) => {
        this.locationOptions = res.data;
        this.siteResourceList = res.data;
      });
    },
    //增加点位
    addDevicePointDetail(item, index) {
      const productKey = item.productKey.split("/")[0];
      this.equipDialogVisible = true;
      //已经被选中的点位表单行必须为禁选择状态
      // this.selectList = [];
      this.loading = true;
      this.currentDevices = index;
      this.tableDataEquip = [];
      this.tableDataEquip1 = [];
      this.selectedPointers = item.siteDeviceBindPoints.map((item) => {
        return {
          propertyName: item.propertyName,
          moduleName: item.moduleName,
        };
      });
      getDevicePoint({ productKey })
        .then((res) => {
          this.loading = false;
          res.modules.forEach((item) => {
            item.properties.forEach((it) => {
              console.log("item.moduleName", item.moduleName);
              it.moduleName = item.moduleName;
              this.tableDataEquip.push(it);
              this.tableDataEquip1.push(it);
            });
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //获取设备数据
    getAllEquipDetail() {
      this.loading = true;
      getDevice({ current: 1, rowCount: 50 })
        .then((res) => {
          this.equipmentOptions = res.rows;
          this.deviceResourceList = res.rows;
        })
        .catch((err) => {
          console.log("捕获异常");
        });
    },
    getDeviceByName(deviceName) {
      this.deviceLoading = true;
      // 设备名称字段用的是description，so...
      getDevice({ current: 1, rowCount: 50, deviceName })
        .then((res) => {
          this.equipmentOptions = res.rows;
          this.deviceLoading = false;
        })
        .catch((err) => {
          this.$message.warning("获取设备失败!");
          this.deviceLoading = false;
        });
    },
    //设备数据源远程搜索的方法
    deviceRemoteMethod(query) {
      if (query !== "") {
        this.getDeviceByName(query);
      } else {
        this.equipmentOptions = this.deviceResourceList;
      }
    },
    //获取设备点位数据
    getDevicePointDetail(productKey, index) {
      this.equipDialogVisible = true;
      this.selectList = [];
      this.loading = true;
      this.currentDevices = index;
      this.tableDataEquip = [];
      this.tableDataEquip1 = [];
      getDevicePoint({ productKey: productKey })
        .then((res) => {
          this.loading = false;
          res.modules.forEach((item) => {
            item.properties.forEach((it) => {
              it.moduleName = item.moduleName;
              this.tableDataEquip.push(it);
              this.tableDataEquip1.push(it);
            });
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //获取页面数据
    findSitePage() {
      this.loadingFlag = true;
      const requestParams = this.listQuery;
      getSitePage(requestParams)
        .then((res) => {
          if (res && res.rows && res.rows.length > 0) {
            this.dataset.pageVO.total = res.total;
            this.dataset.tableData = res.rows;
          } else {
            this.dataset.pageVO.total = 0;
            this.dataset.tableData = [];
          }
          this.loadingFlag = false;
        })
        .catch((error) => {
          console.log(`获取页面数据失败，原因 => ${error}`);
          this.loadingFlag = false;
        })
        .finally(() => {
          this.dialogVisible = false;
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.listQuery = { ...this.listQuery, ...datas.params };
        this.listQuery.current = 1;
        this.findSitePage();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.findSitePage();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editSite(datas.row);
            break;
          case "delete":
            this.deleteSite(datas.row);
            break;
        }
      }
    },
    //编辑功能
    editSite(row) {
      this.dialogVisible = true;
      this.title = "编辑站点";
      this.isEdit = false;
      this.siteForms = { ...row };
      getSiteDetail({ siteId: row.siteId }).then((res) => {
        if (res && res.code === 200) {
          let resultDatas = this.clearDisplayData(res.data);
          this.siteForms = resultDatas;
        } else {
          this.$message.warning("获取站点详情失败！");
        }
      });
      this.getAllEquipDetail();
      this.getAllPlaceDetail();
    },
    //清空不要的数据
    clearDisplayData(datas) {
      delete datas.createdBy;
      delete datas.creationDate;
      delete datas.lastUpdateDate;
      delete datas.lastUpdatedBy;
      delete datas.responesPorintInfoVOS;
      if (!datas.siteBindDevices) {
        datas.siteBindDevices = [];
        return datas;
      }
      datas.siteBindDevices.forEach((item) => {
        item.id = item.deviceId;
        delete item.createdBy;
        delete item.creationDate;
        delete item.lastUpdateDate;
        delete item.lastUpdatedBy;
        delete item.siteId;
        item.productKey =
          item.productKey + "/" + item.deviceName + "/" + item.description;
        item.siteDeviceBindPoints.forEach((ele) => {
          delete ele.createdBy;
          delete ele.creationDate;
          delete ele.lastUpdateDate;
          delete ele.lastUpdatedBy;
          delete ele.pointId;
          delete ele.deviceId;
          ele.imageUrl = ele.icon
            ? envInfo.bgApp.archivePath +
              "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
              ele.icon
            : "";
        });
      });
      return datas;
    },
    buildDeviceParams(params) {
      if (params.siteBindDevices.length === 0) {
        return params;
      }
      params.siteBindDevices.forEach((item) => {
        let tempPk = item.productKey.split("/")[0];
        let tempDn = item.productKey.split("/")[1];
        let tempDesc = item.productKey.split("/")[2];
        item.productKey = tempPk;
        item.deviceName = tempDn;
        item.description = tempDesc;
      });
      return params;
    },
    checkPointerFormValid() {
      return new Promise((resolve) => {
        let validList = [];
        this.siteForms.siteBindDevices.forEach((item, deviceIndex) => {
          item.siteDeviceBindPoints.forEach((ele, pointerIndex) => {
            validList.push(this.singleFormValid(deviceIndex, pointerIndex));
          });
        });
        Promise.all(validList).then((datas) => {
          resolve(datas);
        });
      });
    },
    singleFormValid(deviceIndex, pointerIndex) {
      return new Promise((resolve) => {
        this.$refs[
          "pointerRuleForm_" + deviceIndex + "_" + pointerIndex
        ][0].validate((valid) => {
          if (valid) {
            resolve(valid);
          }
        });
      });
    },
    //站点管理弹出框的保存按钮
    async handleLocationSave() {
      this.$refs.siteForm.validate(async (v) => {
        if (v) {
          // 校验点位form中的必填项
          await this.checkPointerFormValid();
          this.is_click = true;
          setTimeout(() => {
            this.is_click = false;
          }, 2000);
          this.createLoading = false;
          let requestParams = { ...this.siteForms };
          // 拼接siteBindDevices的productKey和deviceName, 匹配设备的description
          requestParams = this.buildDeviceParams(requestParams);
          if (!this.siteForms.siteId) {
            addSite(requestParams)
              .then((res) => {
                if (res.code == 200) {
                  // 成功提示
                  this.$message({
                    message: this.$t("message.saveSuccess"),
                    type: "success",
                  });
                  this.dialogVisible = false;
                  this.findSitePage();
                }
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.dialogVisible = false;
              });
          } else {
            updateSite(requestParams)
              .then((res) => {
                if (res.code == 200) {
                  // 成功提示
                  this.$message({
                    message: this.$t("message.saveSuccess"),
                    type: "success",
                  });
                  this.dialogVisible = false;
                  this.findSitePage();
                }
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.dialogVisible = false;
              });
          }
        }
      });
    },
    //删除row
    deleteSite(row) {
      if (row.pointNum == 0) {
        this.$confirm(
          this.$t("site.deleteConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            deleteSite({ siteId: row.siteId }).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: this.$t("message.deleteSuccess"),
                  type: "success",
                });
              }
              this.findSitePage();
            });
          })
          .catch((error) => {
            console.log(`保存失败，原因 => ${error}`);
          });
      } else {
        this.$confirm(
          this.$t("该站点标签包含") +
            row.pointNum +
            this.$t("个点位，确定删除么？"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            deleteSite({ siteId: row.siteId }).then(() => {
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              this.findSitePage();
            });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      }
    },
    //添加站点按钮
    createPlace() {
      this.siteForms = {
        placeId: "",
        placeName: "",
        siteName: "",
        detailAddress: "",
        longitude: "",
        latitude: "",
        description: "",
        //设备数组
        siteBindDevices: [],
        currentDevices: 0, //
        siteId: "",
      };
      this.title = "新增站点";
      this.dialogVisible = true;
      this.getAllEquipDetail();
      this.getAllPlaceDetail();
      this.$refs.siteForm && this.$refs.siteForm.resetFields();
    },
    //站点弹出框关闭
    handleClose() {
      this.dialogVisible = false;
    },
    //打开地图弹出框
    openMap() {
      const { longitude, latitude } = this.siteForms;
      if (!longitude || !latitude) {
        this.mapSiteObj.longitude = 114.079646;
        this.mapSiteObj.latitude = 22.714196;
      } else {
        this.mapSiteObj.longitude = this.siteForms.longitude;
        this.mapSiteObj.latitude = this.siteForms.latitude;
      }
      this.mapDialogVisible = true;
    },
    mapCancelAndClose() {
      this.mapDialogVisible = false;
    },
    mapSaveAndClose() {
      this.siteForms.longitude = this.mapSiteObj.longitude;
      this.siteForms.latitude = this.mapSiteObj.latitude;
      this.mapDialogVisible = false;
      this.$refs.siteForm.validate();
    },
    syncCenterAndZoom(e) {
      const { lng, lat } = e.target.getCenter();
      this.mapSiteObj.longitude = lng;
      this.mapSiteObj.latitude = lat;
    },
    markerDragOver(e) {
      const { lng, lat } = e.point;
      this.mapSiteObj.longitude = lng;
      this.mapSiteObj.latitude = lat;
    },
    handlerMap({ BMap, map }) {
      console.log(BMap, map);
      this.mapSiteObj.longitude = 116.404;
      this.mapSiteObj.latitude = 39.915;
    },
    // infoWindowClose() {
    //   this.show = false;
    // },
    // infoWindowOpen() {
    //   this.show = true;
    // },
    //打开设备弹出框
    openEquip(val, index) {
      //  GraX8rayYK0/1234
      this.equipDialogDeviceName = val.split("/")[1];
      this.siteForms.siteBindDevices[index].siteDeviceBindPoints = [];
      this.equipDialogVisible = true;
      this.selectedPointers = [];
      this.getDevicePointDetail(val.split("/")[0], index);
    },
    //当用户手动勾选数据行的 Checkbox 时触发的事件
    // handleSelect(val) {
    //   this.selectList = val;
    //   console.log("this.currentDevices", this.currentDevices);
    // },
    handleSelectionChange(val) {
      this.selectList = val;
      console.log("this.currentDevices", val);
    },
    handleCloseEquip() {
      this.equipDialogVisible = false;
    },
    //设备弹出框确定按钮
    handleEquipSave() {
      this.equipDialogVisible = false;
      //把选中的参数带过去
      this.parameterBoxOpen = true;
      if (!this.selectList.length) {
        return;
      }
      console.log("this.selectList", this.selectList);
      for (let i = 0; i < this.selectList.length; i++) {
        const curItem = this.selectList[i];
        const tempObj = {
          icon: "",
          pointName: curItem.description || curItem.propertyName,
          propertyName: curItem.propertyName,
          unit: curItem.unit,
          maxValue: curItem.max,
          minValue: curItem.min,
          moduleName: curItem.moduleName,
        };
        // this.siteForms.siteBindDevices[
        //   this.currentDevices
        // ].siteDeviceBindPoints.push(tempObj);
        let tempList = JSON.parse(
          JSON.stringify(this.siteForms.siteBindDevices)
        );
        tempList[this.currentDevices].siteDeviceBindPoints.push(tempObj);
        this.siteForms.siteBindDevices = tempList;
      }
    },

    //关闭选择设备下面展示的参数
    parameterClose(deviceIndex, pointerIndex) {
      this.siteForms.siteBindDevices[deviceIndex].siteDeviceBindPoints.splice(
        pointerIndex,
        1
      );
    },
    //选择设备的虚线按钮,添加设备
    equipAdd() {
      if (this.siteForms.siteBindDevices.length < 10) {
        this.siteForms.siteBindDevices.push({
          productKey: "",
        });
      }

      // this.siteForms.siteBindDevices.push({
      //   productKey: "",
      // });
    },
    //点位弹出框的搜索按钮
    searchPoint() {
      this.tableDataEquip = [];
      this.getAllEquipDetail();
    },
    //点位弹出框的重置按钮
    resetPoint() {
      this.search = "";
      this.tableDataEquipM();
    },

    //添加点位
    addPoint(productKey, idx) {
      this.currentDevices = idx;
      this.equipDialogVisible = true;
      this.loading = true;
      this.tableDataEquip = [];
      getDevicePoint({ productKey: productKey })
        .then((res) => {
          this.loading = false;
          res.modules.forEach((item) => {
            item.properties.forEach((it) => {
              this.tableDataEquip.push(it);
              this.tableDataEquip1.push(it);
            });
          });
          this.imgList.splice(index, 1);
          this.chooseIndexList.forEach((item) => {
            this.tableDataEquip.splice(item.index, 1);
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //选择地点名称
    selectPlaceName(val) {
      this.siteForms.placeId = val.placeId;
      this.siteForms.placeName = val.placeName;
    },
    //关闭设备
    devicesClose(deviceIndex) {
      this.siteForms.siteBindDevices.splice(deviceIndex, 1);
    },
  },
};
</script>
<style scoped lang="less">
.added_button {
  margin-bottom: 30px;
  text-align: right;
}
.sit-manage-comp {
  padding: 32px 24px;
  .el-icon-error {
    cursor: pointer;
    position: relative;
    top: 5px;
  }
  .map-buttons-container {
    text-align: right;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .site-buttons-container {
    text-align: right;
    margin: 25px 38px;
  }
  .map-search-line {
    align-content: left;
    margin: 15px 0;
  }
  .form-image-line {
    display: flex;
    .el-icon-delete {
      // color: #ff0000;
      color: rgb(228, 116, 112);
      cursor: pointer;
      margin: 12px 0 0 10px;
    }
  }
  // .pointInput {
  //   min-width: 100px;
  //   width: 250px;
  // }
  .avatar-uploader {
    border: 1px dotted #ccc;
    height: 40px;
    width: 40px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    color: #8c939d;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .avatar {
    width: 40px;
    height: 40px;
    display: block;
  }
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
  .row-a-link {
    color: #409eff;
  }
  .advance-filter-container {
    height: 100%;
    width: 100%;
    padding: 32px 32px 96px 32px;
    position: relative;
    .button-container {
      width: 100%;
      height: 64px;
      line-height: 64px;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      .filter-button {
        text-align: center;
        cursor: pointer;
        background-color: #0486fe;
        color: #fff;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        flex: 2;
      }
      .clear-button {
        border: 1px solid #c0c4cc;
        text-align: center;
        cursor: pointer;
        background-color: #fff;
        color: #606266;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        flex: 1;
      }
    }
  }
  // .black {
  //   padding-right: 5.5%;
  // }
  /deep/ .el-drawer__header {
    padding-left: 32px;
  }
  /deep/ .el-drawer__header > :first-child {
    outline: 0;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303133;
  }
  /deep/ .display-none-row {
    display: none;
  }
  // 设置form的label的样式
  /deep/ .el-form-item__label {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #606266;
  }

  /deep/ .el-range-separator {
    min-width: 30px;
  }
  /deep/ .el-table .cell {
    padding-right: 0 !important;
  }
  /deep/ .el-pagination .el-select .el-input {
    margin-right: 0;
  }
  /deep/ .el-pagination__sizes {
    float: right;
    margin-right: 0;
  }
  /deep/ .el-pagination {
    padding-right: 0;
  }
  /deep/ .el-pagination__total {
    float: right;
  }
  /deep/ .el-dialog__header {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303133;
    padding: 20px 20px 10px 32px;
  }
  /deep/ .site-dialog {
    min-width: 1000px;
  }
  // /deep/ .el-dialog__wrapper {
  //   margin-top:50vh;

  // }
}
//地图弹出框
.map-container-box {
  width: 100%;
  height: 450px;
}
.map-container {
  width: 100%;
  height: 100%;
}
//选择设备按钮
.selectEquip {
  // width: 81.5%;
  // width: 90%;
  height: 36px;
  text-align: center;
  line-height: 36px;
  // padding-left: 80px;
  border: 1px #dcdfe6 dotted;
  cursor: pointer;
}
.search-box {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .buttonGroup {
    margin-left: 20px;
    display: flex;
  }
}
.black {
  width: 80%;
  height: 10px;
}
.equipEable {
  margin-top: 20px;
  width: 100%;
}
</style>
